import React, { useEffect, useState } from 'react'
import AddToCartButtonModel from './models'
import styled from '@emotion/styled'
import ButtonStyleModel from '../ButtonStyle/models'
import { AddToCartButton, ProductProvider, useShop } from '@shopify/hydrogen-react'
import { graphQl } from '../../lib/helpers'
import { useAppDispatch, useAppSelector } from '../../redux'
import { setCartOpen } from '../../redux/slices/cart'
import { AiOutlineLoading } from 'react-icons/ai'

interface ContentProps {
  data?: AddToCartButtonModel
  containerProps?: string[]
  dataContext?: any
}

interface ButtonStyleWrapperProps {
  model?: AddToCartButtonModel
  buttonStyle?: ButtonStyleModel
}

const ButtonStyleWrapper = styled.div<ButtonStyleWrapperProps>`
  &.not-available {
    button {
      cursor: default !important;
      opacity: 0.5;
    }

    button:hover {
      opacity: 0.5;
      background-color: ${(props) => props.buttonStyle?.backgroundColor} !important;
    }
  }

  button {
    display: 'inline-block';
    padding-top: ${(props) => props.buttonStyle?.paddingTop};
    padding-right: ${(props) => props.buttonStyle?.paddingRight};
    padding-bottom: ${(props) => props.buttonStyle?.paddingBottom};
    padding-left: ${(props) => props.buttonStyle?.paddingLeft};
    margin-top: ${(props) => props.buttonStyle?.marginTop};
    margin-right: ${(props) => props.buttonStyle?.marginRight};
    margin-bottom: ${(props) => props.buttonStyle?.marginBottom};
    margin-left: ${(props) => props.buttonStyle?.marginLeft};
    font-size: ${(props) => props.buttonStyle?.fontSize};
    background-color: ${(props) => props.buttonStyle?.backgroundColor};
    border-width: ${(props) => props.buttonStyle?.borderThickness};
    border-color: ${(props) => props.buttonStyle?.borderColor};
    border-radius: ${(props) => props.buttonStyle?.borderRadius};
    color: ${(props) => props.buttonStyle?.textColor};
    width: ${(props) =>
      props.buttonStyle?.widthType === 'auto' ? 'auto' : props.buttonStyle?.widthValue};
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.25;
    }

    &:not([disabled]):hover {
      background-color: ${(props) => props.buttonStyle?.backgroundColorHover};
      border-color: ${(props) => props.buttonStyle?.borderColorHover};
      color: ${(props) => props.buttonStyle?.textColorHover};
    }
  }
`

export default function AddToCartButtonElement({
  data,
  containerProps,
  dataContext
}: ContentProps) {
  const shop = useShop()
  const admin = useAppSelector((state) => state.app.isAdmin)
  const enableHeadlessCommerce = useAppSelector((state) => state.app.site.enableHeadlessCommerce)
  const dispatch = useAppDispatch()
  const [product, setProduct] = useState<any>(null)
  const [productId, setProductId] = useState<any>(null)
  const [variant, setVariant] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function load() {
      const query = {
        query: `
          query {
            product(handle: "${dataContext.productHandle}") {
              id
              title
              availableForSale
              variants(first: 100) {
                nodes {
                  id
                  availableForSale
                  selectedOptions {
                    name
                    value
                  }
                }
              }
            }
          }
        `
      }

      try {
        setLoading(true)
        const response = await graphQl(query, 'US', shop.storefrontToken, shop.storeDomain)
        const product = response.data.data?.product

        if (product?.availableForSale) {
          // @TODO: TEMPORARY: support variant picker later
          const firstAvailableVariant = product.variants?.nodes?.find(
            (variant: any) => variant.availableForSale
          )

          if (firstAvailableVariant) {
            setVariant(firstAvailableVariant)
            setProduct(response.data.data.product)
            setProductId(response.data.data.product?.id?.split('/')?.pop())
            return
          }
        } else {
        }

        // TODO: handle product not found
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    if (enableHeadlessCommerce && dataContext?.productHandle) {
      load()
    }
  }, [shop])

  function getTitle() {
    if (!variant?.availableForSale) {
      return data?.notAvailableTitle
    }
  }

  const buttonStyle = data?.buttonStyle === 'custom' ? data?.customStyle : data?._buttonStyle

  if (admin && !enableHeadlessCommerce) {
    return <div>Headless Commerce not enabled.</div>
  }

  if (!enableHeadlessCommerce) {
    return <></>
  }

  if (!product || admin) {
    if (!!!data?.hideWhenProductNotAvailable) {
      return (
        <ButtonStyleWrapper
          model={data}
          buttonStyle={buttonStyle}
          {...containerProps}
          className='not-available'>
          <button>{loading ? <AiOutlineLoading className='animate-spin' /> : getTitle()}</button>
        </ButtonStyleWrapper>
      )
    } else {
      return <></>
    }
  }

  return (
    <ButtonStyleWrapper
      model={data}
      buttonStyle={buttonStyle}
      {...containerProps}
      data-shopify-product-id={productId}
    >
      <ProductProvider data={product}>
        <AddToCartButton
          variantId={variant?.id}
          accessibleAddingToCartLabel={`Add ${product?.title} to cart`}
          quantity={1}
          onClick={() => {
            dispatch(setCartOpen(true))
          }}>
          {data?.title}
        </AddToCartButton>
      </ProductProvider>
    </ButtonStyleWrapper>
  )
}
